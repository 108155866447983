import SimpleParallaxJs from 'simple-parallax-js';
// import Rellax from 'rellax';

export default class SimpleParallax {

  constructor(debug = false) {

    this.debug = debug;
    this.debug && console.log('SimpleParallax init');

    if (this.setVars()) {
      this.debug && console.log('SimpleParallax vars init done');
      this.setEvents();
    }
  }


  setVars()
  {
    this.parallaxElems = document.querySelectorAll('.parallax');

    return this.parallaxElems.length;
  }


  setEvents()
  {
    // setTimeout(() => {
    new SimpleParallaxJs(this.parallaxElems, {
      delay: 2,
      transition: 'cubic-bezier(0,0,0,1)',
      orientation: 'down',
      scale: 1.2,
      overflow: false,
      // customContainer: scrollContent,
      // customWrapper: '.wrapper',
    });
    // }, 10);

  }
}
