// import Vue from 'vue';
// import { createApp } from 'vue';

// import CardSlider from './card-slider/CardSlider.vue';
// import TestOne from './test-one/TestOne.vue';

// const app = createApp({});


// import SimpleSlider from './simple-slider/SimpleSlider.vue';
// import AchievementsTabs from './achievements-tabs/AchievementsTabs.vue';
// import Lightbox from './lightbox/Lightbox.vue';

// import Vuelidate from 'vuelidate';
// import axios from 'axios';

// Vue.use(Vuelidate, axios);

// Vue.component('changes-component', ChangesComponent);
// Vue.component('simple-slider', SimpleSlider);

// Vue.component('CardSlider', CardSlider);
// app.component('CardSlider', CardSlider);


// Vue.component('AchievementsTabs', AchievementsTabs);
// Vue.component('lightbox', Lightbox);
// Vue.component('donut-chart', DonutChart);
// Vue.component('form-component', require('./form-component/FormComponent.vue'));


import TestOne from './TestOne/TestOne.vue';
import TestTwo from './TestTwo/TestTwo.vue';
import TeamSwiper from './TeamSwiper/TeamSwiper.vue';
import PhotoSlider from './PhotoSlider/PhotoSlider.vue';

const registeredVueComponents = {};

registeredVueComponents.TestOne = TestOne;
registeredVueComponents.TestTwo = TestTwo;
registeredVueComponents.TeamSwiper = TeamSwiper;
registeredVueComponents.PhotoSlider = PhotoSlider;

export default registeredVueComponents;
