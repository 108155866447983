// import anime from 'animejs/lib/anime.es';

export default class MobileMenu {

  constructor(debug = false) {

    this.debug = debug;

    if (this.debug) console.log('MobileMenu init');

    if (this.setVars()) {
      if (this.debug) console.log('MobileMenu vars init done');
      this.setEvents();
    }
  }


  setVars()
  {
    this.mobileMenuArr = document.querySelectorAll('[data-hamburger]');
    if (!this.mobileMenuArr.length) return false;

    this.isOpen = false;

    return true;
  }


  setEvents()
  {
    this.mobileMenuArr.forEach((el) => {
      el.addEvent('click.MobileMenu', (e) => {
        // e.preventDefault();
        this.onClick(e.target);
      });
    });
  }

  onClick() {

    const eventType = this.isOpen === true
      ? 'ModalShouldHide'
      : 'ModalShouldShow';

    this.isOpen = !this.isOpen;

    const customEvent = new CustomEvent(eventType, {
      detail: { type: 'mobile-menu' },
    });
    window.dispatchEvent(customEvent);

    this.debug && console.log('[MobileMenu:55]', eventType);
  }
}
