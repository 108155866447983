// import anime from 'animejs/lib/anime.es';

export default class MaterialPreview {

  constructor(debug = false) {

    this.debug = debug;

    if (this.debug) console.log('MaterialPreview init');

    this.RUNNING_FROM_RES = 768;

    if (this.setVars()) {
      if (this.debug) console.log('MaterialPreview vars init done');
      this.setEvents();
    }
  }


  setVars()
  {
    const mediaMatch = window.matchMedia(`(min-width: ${this.RUNNING_FROM_RES}px)`);
    if (!mediaMatch.matches) {
      this.debug && console.log('[HeaderAnimations.js:26] - mathechs');
      return false;
    }

    this.materialPreviewArr = document.querySelectorAll('[data-material]');
    if (!this.materialPreviewArr.length) return false;


    this.materialPreviewTargetEl = document.querySelector('[data-material-preview]');
    if (!this.materialPreviewTargetEl) return false;

    return true;
  }


  setEvents()
  {
    this.materialPreviewArr.forEach((el) => {
      el.addEvent('click.MaterialList', (e) => {
        e.preventDefault();
        this.onClick(e.currentTarget);
      });
    });

    this.materialPreviewArr.forEach((el) => {
      el.addEvent('mouseover.MaterialList', (e) => {
        // e.preventDefault();
        this.onMouseOver(e.currentTarget);
      });
    });

    this.materialPreviewArr.forEach((el) => {
      el.addEvent('mouseout.MaterialList', (e) => {
        // e.preventDefault();
        this.onMouseOut();
      });
    });
  }

  onMouseOver(target) {
    const previewUrl = target.getAttribute('data-material');

    this.materialPreviewTargetEl.querySelector('img').setAttribute('src', previewUrl);
    this.materialPreviewTargetEl.classList.add('active');
  }

  onMouseOut() {
    this.materialPreviewTargetEl.classList.remove('active');
  }

  onClick(target) {
    document.location = target.getAttribute('href');
  }
}
