<template>
  <div ref="testOne">
    <div class="testOne__content">
      <button @click="number++">
        TestOne: {{ number }}
      </button>
    </div>
  </div>
</template>


<script>
/**
 * Simple vue slider by piotr.kubiak@crafton.pl
 *
 */

  // eslint-disable-next-line no-unused-vars
// import { onMounted } from 'vue';
// import { debugVerbose, debug } from '../../debug';

export default {
  name: 'TestOne',
  props: {
    // has nav buttons?
    hasNavButtons: {
      type: Boolean,
      default: true,
    },
    componentCount: {
      type: Number,
      default: 0,
    },
  },
  // setup() {
  //   onMounted(() => {
  //     console.log('TestOne mounted');
  //   });
  // },
  data() {
    return {
      number: 0,
    };
  },
  methods: {
  },
};

</script>

<style scoped lang="scss">
</style>
