export default class Modal {

  constructor(debug = false) {

    this.debug = debug;

    this.MODAL_SLIDE_IN_TIME = 600;

    if (this.debug) console.log('Modal init');

    if (this.setVars()) {
      if (this.debug) console.log('Modal vars init done');
      this.setEvents();
    }
  }

  setVars()
  {
    this.modalEl = document.querySelector('[data-modal]');
    if (!this.modalEl) return false;

    this.modalBackdropEl = document.querySelector('[data-modal-backdrop]');
    if (!this.modalBackdropEl) return false;

    this.modalCloseArr = document.querySelectorAll('[data-modal-close]');
    if (!this.modalCloseArr.length) return false;

    this.hamburgerEl = document.querySelector('[data-hamburger]');

    return true;
  }

  setEvents()
  {
    if (this.debug) console.log('Modal setting events');

    this.modalCloseArr.forEach((closeEl) => {
      closeEl.addEvent('click.Modal', (e) => {
        e.preventDefault();
        this.modalHide(e);
      });
    });

    this.modalBackdropEl.addEvent('click.Modal', (e) => {
      if (e.target.hasAttribute('data-modal-backdrop')) {
        e.preventDefault();
        this.modalHide(e);
      }

    });

    window.addEventListener('ModalShouldShow', (e) => {
      this.modalShow(e.detail ? e.detail.type : null);
    });

    window.addEventListener('ModalShouldHide', (e) => {
      this.modalHide(e.detail ? e.detail.type : null);
    });
  }


  modalHide(type)
  {
    this.debug && console.log('ModalShouldHide - modal type', type);

    this.clearModal();

    this.modalBackdropEl.removeClass('active');
    setTimeout(() => {
      this.modalEl.removeClass('overflow-visible');
      this.modalEl.addClass('overflow-hidden');
    }, this.MODAL_SLIDE_IN_TIME);

    const eventStart = new CustomEvent('ModalClosed');
    window.dispatchEvent(eventStart);

    this.hamburgerEl && this.hamburgerEl.classList.remove('burger--white');
  }


  modalShow(type)
  {
    this.debug && console.log('ModalShouldShow - modal type', type);
    if (!type) {
      console.error('[Modal] no area type set!');
      return;
    }

    const area = this.modalEl.querySelector(`[data-modal-area="${type}"]`);
    if (!area) {
      console.error('[Modal] no area found!', `[data-modal-area="${type}"]`);
      return;
    }

    this.clearModal();

    area.addClass('active');

    this.modalBackdropEl.addClass('active');
    this.modalEl.removeClass('overflow-hidden');
    this.modalEl.addClass('overflow-visible');

    const eventStart = new CustomEvent('ModalOpened');
    window.dispatchEvent(eventStart);

    this.hamburgerEl && this.hamburgerEl.classList.add('burger--white');
  }

  clearModal()
  {
    const areaArr = this.modalEl.querySelectorAll('[data-modal-area]');
    if (areaArr.length > 0) {
      areaArr.forEach((el) => {
        el.removeClass('active');
      });
    }
  }
}
