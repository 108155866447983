import Hamburger from './classes/Hamburger';
import Header from './classes/Header';
import LiteEvents from './classes/LiteEvents';
import Modal from './classes/Modal';
import ReusableCopy from './classes/ReusableCopy';
import ScrollTo from './classes/ScrollTo';
// import SmoothScrollbar from './classes/SmoothScrollbar';
import MaterialList from './classes/MaterialList';
import MobileMenu from './classes/MobileMenu';
import ShowOnScroll from './classes/ShowOnScroll';
// import SimpleMarquee from './classes/SimpleMarquee';
import SimpleParallax from './classes/SimpleParallax';
import MaterialPreview from './classes/MaterialPreview';
import HeaderAnimations from './classes/HeaderAnimations';


class Core {
  // constructor() {
  //   console.log('running core..');
  // }

  static run(stage) {
    // console.log(`[initJS.js] (${stage} scripts)`);

    switch (stage) {
    case 'dom':
      new LiteEvents();
      // new Hamburger(true);
      new Header();
      new ShowOnScroll();
      // new SmoothScrollbar();
      new SimpleParallax();
      new ScrollTo();
      new HeaderAnimations();

      break;

    case 'vue':
      // new Lightbox();
      break;

    case 'page':
      new MaterialList();
      new MobileMenu();
      new Modal();
      new ReusableCopy();
      new MaterialPreview();
      // new SimpleMarquee();
      break;

    default:
    }
  }
}

const readyDOM = (fn) => {
  if (document.attachEvent
    ? document.readyState === 'complete'
    : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

const readyPage = (fn) => {
  window.addEventListener('load', fn);
};

const readyVue = (fn) => {
  window.addEventListener('vueMounted', fn);
};

readyDOM(() => {
  console.log('[initJS.js] readyDOM');
  Core.run('dom');
});

readyPage(() => {
  console.log('[initJS.js] readyPage');
  Core.run('page');
});

let componentsMounted = 0;
readyVue((e) => {
  componentsMounted++;
  // console.log(`[initJS.js] (vue/${componentsMounted}of${e.detail.componentCount}) ready`);
  if (!parseInt(e.detail.componentCount)) {
    console.log('[initJS.js] readyVue');
    Core.run('vue');
  } else if (parseInt(e.detail.componentCount) === componentsMounted) {
    console.log(`[initJS.js] readyVue (${componentsMounted})`);
    Core.run('vue');
  }
});


// window.addEventListener('vueMounted', (e) => {
//   componentMounted++;
//   // console.log('[initJS.js] vue mounted event - componentCount:',
//   //   e.detail.componentCount, componentMounted);
//   parseInt(e.detail.componentCount) === componentMounted && Core.run('vue');
// });
