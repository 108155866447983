<template>
  <swiper
    ref="swiperEl"
    :slides-per-view="'auto'"
    :sssslides-per-view="'4'"
    :space-between="26"
    :centered-slides="true"
    :navigation="true"
    :modules="modules"
    class="teamBoxes__swiper"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    @resize="onSwiperResize"
  >
    <!--
     -->
    <swiper-slide
      v-for="slide in slides"
      :key="slide.id"
    >
      <div class="teamBoxes__box --showOnScroll">
        <img :src="slide.photo" alt="">
        <div>
          <h2 class="teamBoxes__name heading__h5">
            {{ slide.title }}
          </h2>
          <div class="teamBoxes__function label">
            {{ slide.excerpt }}
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>


<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper';
// import { useSwiper, useSwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/navigation';
// import 'swiper/scss/keyboard';
// import 'swiper/scss/mousewheel';
// import 'swiper/scss/autoplay';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const onSlideChange = () => {
      // console.log('slide change');
    };

    const onSwiper = (swiper) => {
      // console.log('[TeamSwiper.vue:66] - onSwiper here');
      const boxWidth = 280;
      const paddingWidth = 26;
      const slidesWidth = swiper.slides.length * boxWidth + paddingWidth;

      if (slidesWidth < window.innerWidth) {
        setTimeout(() => {
          // eslint-disable-next-line no-console
          // console.log('[TeamSwiper.vue:77] - disabled');

          swiper.disable(true, true);
          swiper.el.classList.add('disabled');
        }, 1);
      }
    };

    const onSwiperResize = (swiper) => {
      console.log('[TeamSwiper.vue:66] - onSwiperResize here');
      const boxWidth           = 280;
      const paddingWidth       = 26;
      const slidesWidth        = swiper.slides.length * boxWidth
        + (swiper.slides.length - 1) * paddingWidth;
      const swiperWrapperWidth = swiper.$el[0].offsetWidth;

      if (slidesWidth < swiperWrapperWidth) {
        // console.log('[TeamSwiper.vue:90] - disabled', slidesWidth,
        //   window.innerWidth, swiper.$el[0].offsetWidth);

        swiper.disable(true, true);
        swiper.el.classList.add('disabled');
      } else {
        // console.log('[TeamSwiper.vue:90] - enabled');

        swiper.enable();
        swiper.el.classList.remove('disabled');
      }
    };

    return {
      // swiper,
      // swiperSlide,
      onSwiper,
      onSlideChange,
      onSwiperResize,
      modules: [Navigation],
    };
  },
  data() {
    return {
      enabled: false,
      boxWidth: 280,
      paddingWidth: 26,
      // swiperObj: null,
    };
  },
  // computed: {
  //   shouldBeEnabled() {
  //     return this.mobile;
  //   },
  // },
  // mounted() {
  // },
  // methods: {
  //   onSwiperResize2(e)
  //   {
  //     // eslint-disable-next-line no-console
  //     console.log('[TeamSwiper.vue:117] - onSwiperResize', onSwiperResize, e);
  //   },
  // },
};
</script>

<style scoped lang="scss">
  .swiper {
    // min-height: 0;
    min-width: 0;
    width: 100%;
  }

  .swiper.disabled {
    .swiper-slide {
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }

  // .swiper-wrapper {
  //   // min-height: 0;
  //   min-width: 0;
  //   // max-height: 100%;
  // }

  // .swiper-slide {
  //   // min-height: 0;
  //   // min-width: 0;
  //       // max-width: 280px;
  //   // max-height: 100%;
  // }

  // @media (max-width: 479px) {
  //   .swiper-slide {
  //     max-width: calc(100vw - 82px);
  //   }
  // }
</style>
