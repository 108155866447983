// import anime from 'animejs/lib/anime.es';
import anime from 'animejs/lib/anime.es';
// import { observeOnce } from 'tiny-intersection-observer';
import { observe } from 'tiny-intersection-observer';


export default class MaterialList {

  constructor(debug = false) {

    this.debug = debug;

    if (this.debug) console.log('MaterialList init');

    if (this.setVars()) {
      if (this.debug) console.log('MaterialList vars init done');
      this.setEvents();
    }
  }


  setVars()
  {
    this.materialListArr = document.querySelectorAll('[href*="materials"]');
    if (!this.materialListArr.length) return false;

    this.materialListModal = document.querySelector('[data-modal-area="material-list"]');

    this.setMaterialListVars();

    return true;
  }


  setEvents()
  {
    this.materialListArr.forEach((el) => {
      el.addEvent('click.MaterialList', (e) => {
        const materialLink = e.currentTarget.getAttribute('data-material');
        this.debug && console.log('[MaterialList.js:40] - materialLink', materialLink);

        if (e.currentTarget.hasAttribute('data-material-list-accordion')
          && this.hiddenMaterialListEl) {
          this.debug && console.log('[MaterialList.js:40] - 2');

          e.preventDefault();
          if (this.hiddenMaterialListEl.style.maxHeight === `${this.hiddenMaterialListElHeight}px`)
          {
            this.hiddenMaterialListEl.style.maxHeight = '0px';
            e.currentTarget.classList.remove('active');
          } else {
            this.hiddenMaterialListEl.style.maxHeight = `${this.hiddenMaterialListElHeight}px`;
            e.currentTarget.classList.add('active');
          }
        }
        else if (!materialLink) {
          this.debug && console.log('[MaterialList.js:40] - 3');
          e.preventDefault();

          this.onClick(e.target);
        }
        else {
          this.debug && console.log('[MaterialList.js:40] - 4');
        }
      });
    });

    if (this.materialListModal) {
      // observeOnce(
      //   this.materialListModal,
      //   () => this.onIntersectionMaterialList(),
      //   {
      //     rootMargin: '100px',
      //     threshold: 0.2,
      //   },
      // );
      observe(
        this.materialListModal,
        () => this.onIntersectionMaterialListHeading(),
        () => this.onNoIntersection(),
        {
          rootMargin: '100px',
          threshold: 0.2,
        },
      );

      observe(
        this.materialListModal,
        () => this.onIntersectionMaterialList(),
        () => this.onNoIntersection(),
        {
          rootMargin: '100px',
          threshold: 0.2,
        },
      );
    }

    // window.addEventListener('ModalOpened', () => {
    //   this.materialListContentEl.classList.add('active');
    // });

    // window.addEventListener('ModalClosed', () => {
    //   this.materialListContentEl.classList.remove('active');
    // });
  }

  setMaterialListVars()
  {
    this.materialLinkAccordionEl = document.querySelector('[data-material-list-accordion]');
    if (this.materialLinkAccordionEl) {
      this.hiddenMaterialListEl = this.materialLinkAccordionEl.nextElementSibling;
      const currentHeight = this.hiddenMaterialListEl.style.maxHeight;

      this.hiddenMaterialListEl.style.maxHeight = 'none';
      this.hiddenMaterialListElHeight = this.hiddenMaterialListEl.offsetHeight;
      this.hiddenMaterialListEl.style.maxHeight = currentHeight;
    }
  }

  onClick() {
    if (this.debug) console.log('[MaterialList:55]', 'material-list');

    const eventStart = new CustomEvent('ModalShouldShow', {
      detail: { type: 'material-list' },
    });
    window.dispatchEvent(eventStart);
  }


  onNoIntersection()
  {
    this.debug && console.log('[MaterialList.js:70] - onNoIntersection');
  }


  onIntersectionMaterialListHeading() {
    this.debug && console.log('[MaterialList.js:70] - onIntersectionMaterialList');

    const tl = anime.timeline({
      easing: 'easeOutExpo',
      // duration: 1000,
      autoplay: false,
      loop: false,
      // direction: 'alternate',
    });

    tl
      .add({
        targets: '.materialList__heading',
        translateY: '-40px',
        opacity: 0,
        duration: 0,
      }, 0)
      .add({
        targets: '.materialList__heading',
        translateY: 0,
        opacity: 1,
        duration: 500,
        // complete: () => {
        //   this.debug && console.log('[MaterialList.js:70] - onIntersectionMaterialList complete 1');
        //   // const header = document.querySelector('.materialList__heading');
        //   // header.style = '';
        //   // header.classList.add('active');
        // },
      }, 250)
    ;

    tl.play();
  }


  onIntersectionMaterialList() {
    const tl = anime.timeline({
      easing: 'easeOutExpo',
      autoplay: false,
      loop: false,
      direction: 'alternate',
    });

    tl
      .add({
        targets: '.materialList__materials .materialList__material',
        duration: 0,
        direction: 'alternate',
        translateY: '40px',
        opacity: 0,
      }, 0)
      .add({
        targets: '.materialList__materials .materialList__material',
        delay(el, i) { return i * 100 },
        duration: 1000,
        direction: 'alternate',
        translateY: '0px',
        opacity: 1,
      }, 300)
    ;

    tl.play();
  }
}
