import anime from 'animejs/lib/anime.es';

/**
 * Usage:
 *   <a href="#"
 *      class="jsScrollTo"
 *      data-scrollto-id=""
 *      data-scrollto-fps="30"
 *      data-scrollto-speed="120"
 *      data-scrollto-duration="1500"></a>
 *
 * Params:
 *    - data-scrollto-speed: pixels / animation frame (has priority over duration parameter)
 *    - data-scrollto-duration: in miniseconds,  independent of page height
 *    - data-scrollto-id: target element to scroll-to (not supported yet), by default scrolls to page top
 */

export default class ScrollTo {

  constructor(debug = false) {

    this.debug = debug;

    if (this.debug) console.log('ScrollTo init');

    // just some special limitation for mylna
    this.RUNNING_TO_RES = 599;

    if (this.setVars()) {
      if (this.debug) console.log('ScrollTo vars init done');
      this.setEvents();
    }
  }

  setVars() {

    this.scrollToArr = document.querySelectorAll('[data-scrollto]');
    if (!this.scrollToArr.length) return false;

    const mediaMatch = window.matchMedia(`(max-width: ${this.RUNNING_TO_RES}px)`);
    this.mobile = mediaMatch.matches;

    this.debug && console.log('[ScrollTo.js:28] - this.mobile', this.mobile);


    return true;
  }

  setEvents() {
    this.scrollToArr.forEach((itemEl) => {
      itemEl.addEvent('click.ScrollTo', (e) => {
        e.preventDefault();

        this.onClick(e);
      });
    });
  }


  onClick(e) {
    e.preventDefault();

    const clickedEl = e.target;

    const scrollToTargetOffset   = this.getScrollToTargetOffset(clickedEl);
    const scrollToAbsoluteOffset = this.getScrollToAbsoluteOffset(clickedEl, scrollToTargetOffset);
    const scrollToDuration       = this.getScrollToDuration(clickedEl);

    const scrollElement = window.document.scrollingElement
      || window.document.body || window.document.documentElement;

    anime({
      targets:   scrollElement,
      scrollTop: scrollToAbsoluteOffset,
      duration:  scrollToDuration,
      easing:    'easeInOutQuad',
    });
  }


  getScrollToTargetOffset(clickedEl)
  {
    const scrollToTargetId = clickedEl.getAttribute('data-scrollto-id');

    const scollToTargetEl = scrollToTargetId
      ? document.querySelector(`#${scrollToTargetId}`)
      : null;

    const scrollToTargetOffset = scollToTargetEl
      ? window.pageYOffset + scollToTargetEl.getBoundingClientRect().top
      : 0;
    this.debug && console.log('[ScrollTo] getScrollToTargetOffset: ', scrollToTargetOffset);

    return scrollToTargetOffset;
  }


  getScrollToAbsoluteOffset(clickedEl, scrollToTargetOffset)
  {
    let scrollToOffset = parseInt(clickedEl.getAttribute('data-scrollto-offset'));
    scrollToOffset = Number.isNaN(scrollToOffset)
      ? scrollToTargetOffset
      : scrollToTargetOffset + scrollToOffset;
    this.debug && console.log('[ScrollTo] getScrollToAbsoluteOffset: ', scrollToOffset);

    return scrollToOffset;
  }


  getScrollToDuration(clickedEl)
  {
    let scrollToDuration = clickedEl.getAttribute('data-scrollto-duration');
    scrollToDuration = scrollToDuration
      ? parseFloat(scrollToDuration)
      : 100;

    this.debug && console.log('[ScrollTo] getScrollToDuration: ', scrollToDuration);

    return scrollToDuration;
  }
}
