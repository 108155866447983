// import { throttle } from '../vendors/helpers/throttle';
// import IntersectionObserver from 'intersection-observer-polyfill';

/*
  Usage:

    Args:
      data-show-on-scroll-threshold - when to show - use thresholds from this.THRESHOLD_ARR
                                      when no threshold given uses this.THRESHOLD_DEFAULT

    Example:
      <img class="showOnScroll" data-show-on-scroll-threshold="0.5">

    !! Remember to add styles to make it work:
      html\src\scss\vendors\_showOnScroll.scss

*/


export default class ShowOnScroll {

  constructor(debug = false) {
    this.debug = debug;
    this.debug && console.log('ShowOnScroll init');

    if (this.setVars()) this.setEvents();
  }


  setVars()
  {
    this.items    = document.querySelectorAll('.showOnScroll');
    // this.elemsArr = [...document.querySelectorAll('.showOnScroll')];
    this.elemsNodeList = document.querySelectorAll('.showOnScroll');

    if (!this.items) return;

    this.SCROLL_THROTTLE  = 50;
    this.SCROLL_THROTTLE  = 50;
    this.offsetTop        = 70;
    this.offsetBottom     = 50;
    this.delay            = 100;
    this.list             = [];
    this.showList         = [];

    this.DELAY_INIT         = 100;
    this.CSS_TRANSITON_TIME = 1000; // must be the same as .showOnScroll--active transition time
    this.THRESHOLD_DEFAULT  = 0.1;
    // eslint-disable-next-line no-magic-numbers
    this.THRESHOLD_ARR      = [0.1, 0.5, 0.9]; // list of threshold being checked

    return true;
  }


  setEvents()
  {
    this.debug && console.log('ShowOnScroll setEvents');

    this.elemsNodeList.forEach((el) => {
      el.addClass('showOnScroll--hidden');
      // console.log('el', el, el.classList);
    });

    setTimeout(() => {
      this.setupObserver();
      setTimeout(() => {
        // this.elemsNodeList.forEach(this.observer.observe.bind(this.observer));
        this.elemsNodeList.forEach((el) => {
          // this.debug && console.log('setting observer to: ', el);
          this.observer.observe(el);
        });
      }, this.DELAY_INIT);
    }, 0);

    this.setupObserver();
  }

  setupObserver()
  {
    const options = {
      // root: document.querySelector('main'),
      // rootMargin: '0px',
      // threshold: [this.THRESHOLD_DEFAULT],
      threshold: this.THRESHOLD_ARR,
    };

    const callback = (entries/* , observer */) => {
      // console.log(entries);

      this.doActionsForIntersect(entries.filter(el => el.isIntersecting === true));
    };

    this.observer = new IntersectionObserver(callback, options);
  }

  // eslint-disable-next-line class-methods-use-this
  doActionsForIntersect(entries)
  {
    let key = 0;
    const TIME_SEPARATOR = 100;
    entries.forEach((entry) => {

      setTimeout(() => {

        let elTreshold = parseFloat(entry.target.getAttribute('data-show-on-scroll-threshold'));
        elTreshold = Number.isNaN(elTreshold) ? this.THRESHOLD_DEFAULT : 0;

        this.debug && console.log('checking',
          entry.intersectionRatio, elTreshold, (TIME_SEPARATOR * key));


        if (entry.intersectionRatio >= elTreshold) {
          this.debug && console.log('showing', entry.target, entry, elTreshold);

          this.observer.unobserve(entry.target);
          entry.target.addClass('showOnScroll--active');

          setTimeout(() => {

            if (this.debug) {
              console.log('removig animation classes from: ',
                entry.intersectionRatio, elTreshold, entry.target);
            }

            entry.target.removeClass('showOnScroll');
            entry.target.removeClass('showOnScroll--left');
            entry.target.removeClass('showOnScroll--right');
            entry.target.removeClass('showOnScroll--hidden');
            entry.target.removeClass('showOnScroll--active');
          }, this.CSS_TRANSITON_TIME);
        }

      }, TIME_SEPARATOR * key);

      key++;
    });
  }
}
