<template>
  <div ref="testTwo">
    <div class="testTwo__content">
      <button @click="number++">
        TestTwo: {{ number }}
      </button>
    </div>
  </div>
</template>


<script>
/**
 * Simple vue slider by piotr.kubiak@crafton.pl
 *
 */

  // eslint-disable-next-line no-unused-vars
import { debugVerbose, debug } from '../../debug';

export default {
  name: 'TestTwo',
  props: {
    // has nav buttons?
    hasNavButtons: {
      type: Boolean,
      default: true,
    },
    componentCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      number: 0,
    };
  },
  methods: {
  },
};

</script>

<style scoped lang="scss">
</style>
