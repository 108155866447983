// import Vue from 'vue';
import { createApp } from 'vue';

// import Vuelidate from 'vuelidate';
// import VueRouter from 'vue-router'
// import store from './store/index';


// i18n
// require('./i18n/index.js');

// filters
// require('./filters/index.js');

// Vue.use(VueRouter);
// Vue.use(Vuelidate);


// var router = new VueRouter({
//    mode: 'history',
//    routes: []
// });

// window.AppRouter = router;

// components
// require('./components/index');

import registeredVueComponents from './components/index';

// import TestOne from './components/TestOne/TestOne.vue';
// import TestTwo from './components/TestTwo/TestTwo.vue';

// const components = {};
// components.TestOne = TestOne;
// components.TestTwo = TestTwo;

import { globalMixin } from './mixins/globalMixin';

const ready = (fn) => {
  if (document.attachEvent
    ? document.readyState === 'complete'
    : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

/**
 * Two alteranative ways of enabling vue compoments
 *  - one or multiple vue instances
 */

/**
 * Multiple Vue instances version - one for each component
 */
ready(() => {
  const foundVueComponents = document.querySelectorAll('[data-vue-component]');

  if (foundVueComponents.length) {
    foundVueComponents.forEach((elem) => {

      const componentName  = elem.getAttribute('data-vue-component');
      const componentProps = elem.getAttribute('data-vue-component-props');
      // eslint-disable-next-line no-console
      // console.log('[initVue.js:66] - componentProps', JSON.parse(componentProps));

      const app = createApp(
        registeredVueComponents[componentName],
        JSON.parse(componentProps),
        // { componentCount: foundVueComponents.length },
      );

      // alternatively we can hack it like this but linters don't like it
      // const app = createApp(require(`./components/${componentName}/${componentName}.vue`).default);

      // dispatch `vueMounted` event for each component
      app.mixin(globalMixin);

      app.mount(elem);
    });
  }
});

/**
 * One Vue instance version - one for all components
 */
// ready(() => {
//   setTimeout(() => {
//     // console.log('document vue init');
//     window.App = new Vue({
//       el: '#app_handle',
//       mounted() {
//         // console.log('document vue ready mounted');
//         const event = new CustomEvent('vueMounted', { detail: 'vue' });
//         window.dispatchEvent(event);
//       },
//     });
//   }, 0);
// });
