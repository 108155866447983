<template>
  <div>
    <swiper
      ref="swiperEl"
      :slides-per-view="'1'"
      :centered-slides="true"
      :navigation="true"
      :modules="modules"
      :loop="true"
      :pagination="{
        type: 'fraction',
        renderFraction:
          (currentClass, totalClass) =>
            `<span class='${currentClass}'></span><span class='swiper-pagination-line'></span><span class='${totalClass}'></span>`,
        formatFractionCurrent: (number) => String(number).padStart(2, '0'),
        formatFractionTotal: (number) => String(number).padStart(2, '0'),
      }"
      class="photoSlider__swiper"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      @slideChangeTransitionStart="onSlideChangeTransitionStart"
      @slideChangeTransitionEnd="onSlideChangeTransitionEnd"
      @resize="onSwiperResize"
    >
      <!--
       -->
      <swiper-slide
        v-for="slide in slides"
        :key="slide.id"
      >
        <div class="photoSlider__slide" :style="{ backgroundImage: `url(${slide.photo})` }">
          <div class="photoSlider__slideContent">
            <div class="photoSlider__contentTop">
              <!-- <h2 class="photoSlider__name heading__h2">
                {{ slide.title }}
              </h2> -->
            </div>
            <div class="photoSlider__contentBottom">
              <!-- <div class="photoSlider__label label">
                {{ slide.label }}
              </div>
              <div class="photoSlider__text">
                {{ slide.text }}
              </div> -->
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="photoSlider__contentOverlay">
      <TransitionGroup tag="ul" name="list">
        <li
          v-for="slide in visibleLabels"
          :key="slide.id"
          class="photoSlider__labelText"
        >
          <div class="photoSlider__label label">
            {{ slide.label }}
          </div>
          <div class="photoSlider__text">
            {{ slide.text }}
          </div>
        </li>
      </TransitionGroup>
    </div>
  </div>
</template>


<script>

// import { ref, onMounted } from 'vue';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper';
// import { useSwiper, useSwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/navigation';
// import "swiper/scss/pagination";
// import 'swiper/scss/keyboard';
// import 'swiper/scss/mousewheel';
// import 'swiper/scss/autoplay';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  setup() {
    // const count = ref(0);
    // onMounted(() => {
    //   console.log('mounted in the composition api!', count.value);
    // });
    const onSlideChange = (swiper) => {
      // console.log('slide change', swiper.activeIndex, this);
      // window.dispatchEvent(new CustomEvent('SwiperSlideChange', {
      //   detail: {
      //     slide: swiper.activeIndex
      //     type:
      //   },
      // }));
    };

    const onSlideChangeTransitionStart = (swiper) => {
      // console.log('slideChangeTransitionStart', swiper.activeIndex, this);
      window.dispatchEvent(new CustomEvent('SwiperSlideChange', {
        detail: {
          slide: swiper.realIndex,
          type: 'start',
        },
      }));
    };

    const onSlideChangeTransitionEnd = (swiper) => {
      // console.log('slideChangeTransitionStart', swiper.activeIndex, this);
      window.dispatchEvent(new CustomEvent('SwiperSlideChange', {
        detail: {
          slide: swiper.realIndex,
          type: 'end',
        },
      }));
    };

    const onSwiper = (swiper) => {
      /*
      // console.log('[PhotoSlider.vue:66] - onSwiper here');
      // const boxWidth = 280;
      // const paddingWidth = 26;
      // const slidesWidth = swiper.slides.length * boxWidth + paddingWidth;

      // if (slidesWidth < window.innerWidth) {
      //   setTimeout(() => {
      //     // eslint-disable-next-line no-console
      //     // console.log('[PhotoSlider.vue:77] - disabled');

      //     swiper.disable(true, true);
      //     swiper.el.classList.add('disabled');
      //   }, 1);
      // }
      */
    };

    const onSwiperResize = (swiper) => {
      // console.log('[PhotoSlider.vue:66] - onSwiperResize here');
      // const boxWidth           = 280;
      // const paddingWidth       = 26;
      // const slidesWidth        = swiper.slides.length * boxWidth
      //   + (swiper.slides.length - 1) * paddingWidth;
      // const swiperWrapperWidth = swiper.$el[0].offsetWidth;

      // if (slidesWidth < swiperWrapperWidth) {
      //   // console.log('[PhotoSlider.vue:90] - disabled', slidesWidth,
      //   //   window.innerWidth, swiper.$el[0].offsetWidth);

      //   swiper.disable(true, true);
      //   swiper.el.classList.add('disabled');
      // } else {
      //   // console.log('[PhotoSlider.vue:90] - enabled');

      //   swiper.enable();
      //   swiper.el.classList.remove('disabled');
      // }
    };

    return {
      // swiper,
      // swiperSlide,
      onSwiper,
      onSlideChange,
      onSwiperResize,
      onSlideChangeTransitionStart,
      onSlideChangeTransitionEnd,
      modules: [Navigation, Pagination],
      // count,
    };
  },
  data() {
    return {
      slideLabel: 'slide label',
      slideText: 'slide text',
      slideActiveId: 0,
      // enabled: false,
      // boxWidth: 280,
      // paddingWidth: 26,
      // swiperObj: null,
    };
  },
  computed: {
    activeSlide() {
      return this.slides[this.slideActiveId];
    },
    visibleLabels() {
      return this.slides.filter(slide => slide.id === this.slideActiveId);
    },
  },
  mounted() {
    // console.log('mounted standard!');
    window.addEventListener('SwiperSlideChange', (e) => {
      this.animateLabels(e.detail);
    });

    this.slideLabel = this.slides[0].label;
    this.slideText = this.slides[0].text;
  },
  // computed: {
  //   shouldBeEnabled() {
  //     return this.mobile;
  //   },
  // },
  // mounted() {
  // },
  methods: {
    animateLabels(detail)
    {
      // eslint-disable-next-line no-console
      // console.log('[PhotoSlider.vue:180] - detail', detail, this.slides);
      // this.slideLabel = this.slides[detail.slide].label;
      // this.slideText = this.slides[detail.slide].text;
      this.slideActiveId = detail.slide;
    },
//     window.addEventListener('swiperSlideChange', (e) => {
//       this.modalShow(e.detail ? e.detail.type : null);
//     });
//   //   onSwiperResize2(e)
//   //   {
//   //     // eslint-disable-next-line no-console
//   //     console.log('[PhotoSlider.vue:117] - onSwiperResize', onSwiperResize, e);
//   //   },
  },
};
</script>

<style scoped lang="scss">
  .swiper {
    // min-height: 0;
    min-width: 0;
    width: 100%;
  }

  .swiper.disabled {
    .swiper-slide {
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }

  .list-enter-active,
  .list-leave-active {
    transition: all .75s ease;
  }
  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    // transform: translateX(25px);
  }
  // .swiper-wrapper {
  //   // min-height: 0;
  //   min-width: 0;
  //   // max-height: 100%;
  // }

  // .swiper-slide {
  //   // min-height: 0;
  //   // min-width: 0;
  //       // max-width: 280px;
  //   // max-height: 100%;
  // }

  // @media (max-width: 479px) {
  //   .swiper-slide {
  //     max-width: calc(100vw - 82px);
  //   }
  // }
</style>
