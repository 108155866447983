import anime from 'animejs/lib/anime.es';
import { observeOnce } from 'tiny-intersection-observer';
// import { observe, observeOnce } from 'tiny-intersection-observer';

export default class HeaderAnimations {

  constructor(debug = false) {

    this.debug = debug;

    if (this.debug) console.log('HeaderAnimations init');

    this.RUNNING_FROM_RES = 768;

    if (this.setVars()) {
      if (this.debug) console.log('HeaderAnimations vars init done');
      this.setEvents();
    }
  }


  setVars()
  {
    const mediaMatch = window.matchMedia(`(min-width: ${this.RUNNING_FROM_RES}px)`);
    if (!mediaMatch.matches) {
      this.debug && console.log('[HeaderAnimations.js:26] - mathechs');
      return false;
    }

    this.headerSectionNoHeader = document.querySelector('[data-header-section-noheader]');
    this.headerSectionCircle   = document.querySelector('[data-header-section-circle]');
    this.headerSectionGeneric  = document.querySelector('[data-header-section-generic]');
    this.headerSectionContact  = document.querySelector('[data-header-section-contact]');
    this.headerSectionMaterial = document.querySelector('[data-header-section-material]');
    if (!this.headerSectionGeneric
      && !this.headerSectionNoHeader
      && !this.headerSectionCircle
      && !this.headerSectionMaterial
      && !this.headerSectionContact) return false;

    return true;
  }


  setEvents()
  {
    // observe(
    //   // document.querySelector('[data-header-section]'),
    //   this.headerSectionCircle,
    //   () => this.onIntersection(),
    //   () => this.onNoIntersection(),
    //   {
    //     rootMargin: '100px',
    //     threshold: 0.2,
    //   },
    // );

    // specific sections
    if (this.headerSectionCircle) {
      observeOnce(
        this.headerSectionCircle,
        () => this.onIntersectionCircle(),
        {
          rootMargin: '100px',
          threshold: 0.2,
        },
      );
    } else if (this.headerSectionGeneric) {
      observeOnce(
        this.headerSectionGeneric,
        () => this.onIntersectionGeneric(),
        {
          rootMargin: '100px',
          threshold: 0.2,
        },
      );
    } else if (this.headerSectionContact) {
      observeOnce(
        this.headerSectionContact,
        () => this.onIntersectionContact(),
        {
          rootMargin: '100px',
          threshold: 0.2,
        },
      );
    } else if (this.headerSectionMaterial) {
      observeOnce(
        this.headerSectionMaterial,
        () => this.onIntersectionMaterial(),
        {
          rootMargin: '100px',
          threshold: 0.2,
        },
      );
    } else if (this.headerSectionNoHeader) {
      // all other sections
      observeOnce(
        this.headerSectionNoHeader,
        () => this.onIntersectionContact(),
        {
          rootMargin: '100px',
          threshold: 0.2,
        },
      );
    }
  }

  onIntersectionGeneric() {
    this.debug && console.log('[HeaderAnimations.js:70] - onIntersectionGeneric');

    const tl = anime.timeline({
      easing: 'easeOutExpo',
      duration: 1000,
      delay: 0,
    });

    tl
      .add({
        targets: '.pageHeaderGeneric__image',
        opacity: [1],
        scale: [1.05],
        duration: 3000,
      }, 0)
      .add({
        targets: '.pageHeaderGeneric__imageMaskFull--t',
        // right: '56.514%',
        translateX: ['-56.514%'],
        duration: 2000,
      }, 0)
      .add({
        targets: '.pageHeaderGeneric__imageMaskFull--square',
        // right: '52.71%',
        translateX: ['-52.71%'],
        duration: 2000,
      }, 0)

      .add({
        targets: '.pageHeaderGeneric__textWrap',
        translateY: ['40px', '0'],
        opacity: [0, 1],
        duration: 1000,
      }, 1000)
      .add({
        targets: '.header',
        translateY: ['-40px', '0'],
        opacity: [0, 1],
        duration: 1000,
        complete: () => {
          this.debug && console.log('[HeaderAnimations.js:110] - onIntersectionGeneric complete');
          const header = document.querySelector('[data-header]');
          header.style = '';
          header.classList.add('active');
        },
      }, 1000)
    ;
  }

  onIntersectionContact() {
    this.debug && console.log('[HeaderAnimations.js:70] - onIntersectionContact');

    const tl = anime.timeline({
      easing: 'easeOutExpo',
      duration: 1000,
    });

    tl
      .add({
        targets: '.pageHeaderContact__textWrap',
        translateY: '40px',
        opacity: 0,
        duration: 0,
      }, 0)
      .add({
        targets: '.header',
        translateY: '-40px',
        opacity: 0,
        duration: 0,
      }, 0)
      .add({
        targets: '.pageHeaderContact__imageMaskFull',
        right: '100%',
        duration: 1500,
      }, 0)
      .add({
        targets: '.pageHeaderContact__textWrap',
        translateY: 0,
        opacity: 1,
        duration: 1000,
      }, 500)
      .add({
        targets: '.header',
        translateY: 0,
        opacity: 1,
        duration: 1000,
        complete: () => {
          this.debug && console.log('[HeaderAnimations.js:110] - onIntersectionContact complete');
          const header = document.querySelector('[data-header]');
          header.style = '';
          header.classList.add('active');
        },
      }, 500)
    ;
  }

  onIntersectionMaterial() {
    this.debug && console.log('[HeaderAnimations.js:70] - onIntersectionMaterial');

    const tl = anime.timeline({
      easing: 'easeOutExpo',
      duration: 1000,
    });

    tl
      .add({
        targets: '.materialDetails__right',
        translateY: '40px',
        opacity: 0,
        duration: 0,
      }, 0)
      .add({
        targets: '.header',
        translateY: '-40px',
        opacity: 0,
        duration: 0,
      }, 0)
      .add({
        targets: '.materialDetails__imageMaskFull',
        left: '49.048%',
        duration: 1500,
      }, 0)
      .add({
        targets: '.materialDetails__right',
        translateY: 0,
        opacity: 1,
        duration: 1000,
      }, 1000)
      .add({
        targets: '.header',
        translateY: 0,
        opacity: 1,
        duration: 1000,
        complete: () => {
          this.debug && console.log('[HeaderAnimations.js:110] - onIntersectionContact complete');
          const header = document.querySelector('[data-header]');
          header.style = '';
          header.classList.add('active');
        },
      }, 1000)
    ;
  }

  onIntersectionCircle() {
    this.debug && console.log('[HeaderAnimations.js:46] - onIntersectionCircle');

    const tl = anime.timeline({
      easing: 'easeOutExpo',
      duration: 1000,
      delay: 0,
    });

    tl
      .add({
        targets: '.pageHeaderGeneric__image',
        opacity: [1],
        scale: [1.05],
        duration: 3000,
      }, 0)
      .add({
        targets: '.pageHeaderGeneric__imageMaskCut',
        translateX: ['50%'],
        translateY: ['calc(-100% / 2 + 270px / 2)'],
        duration: 2000,
      }, 0)
      .add({
        targets: '.pageHeaderGeneric__imageMask',
        translateX: ['0%', '50%'],
        translateY: ['calc(-100% / 2 + 270px / 2)', 'calc(-100% / 2 + 270px / 2)'],
        rotate: ['0deg', '0deg', '180deg'],
        duration: 2000,
      }, 0)

      .add({
        targets: '.pageHeaderGeneric__textWrap',
        translateY: ['40px', '0'],
        opacity: [0, 1],
        duration: 1000,
      }, 1500)
      .add({
        targets: '.header',
        translateY: ['-40px', '0'],
        opacity: [0, 1],
        duration: 1000,
        complete: () => {
          this.debug && console.log('[HeaderAnimations.js:110] - onIntersectionCircle complete');
          const header = document.querySelector('[data-header]');
          header.style = '';
          header.classList.add('active');
        },
      }, 1500)
    ;
  }

  onNoIntersection() {
    this.debug && console.log('[HeaderAnimations.js:46] - onNoIntersection');
  }
}
