import anime from 'animejs/lib/anime.es';
// import { TweenLite, CSSPlugin } from 'gsap';
import { throttle } from '../vendors/helpers/throttle';


export default class Header {

  constructor(debug = false)
  {
    this.TRANSITION_TIME        = 300;
    this.THROTTLE_DELAY         = 50;
    this.IDLE_DETECTION_DELAY   = 50;
    this.RUNNING_FROM_RES       = 300;
    this.MIMUM_DELTA            = 10;

    this.REDUCED_HEIGHT         = 72;
    // this.DEFAULT_MAX_HEIGHT     = 144; // automatic - this.headerEl.offsetHeight
    this.lastScrollTop          = null; // this.getScrollPosition();
    this.lastBusyScrollTop      = null; // this.getScrollPosition();
    this.scrollUp               = false;

    this.isRunning = {
      hideTop: false,
      showTop: false,
    };

    this.debug = debug;
    if (this.debug) console.log('Header init');

    if (!this.setVars()) return;
    this.setEvents();

    if (this.debug) console.log('Header succesful');
  }

  setVars()
  {
    this.header = document.querySelector('[data-header]');
    if (!this.header) return;

    this.headerEl = document.querySelector('[data-header-height-reduced]');
    if (!this.headerEl) return;

    // optional elements (like scroll to top button)
    //   that should get 'active' class when header is in 'sticky' mode
    this.activeElArr = document.querySelectorAll('[data-header-element-active]');

    this.searchForm = document.querySelector('[data-search-form]');


    this.classes = {
      sticky:   'header--sticky',
      stickyUp: 'header--stickyUp',
    };

    this.DEFAULT_HEIGHT = this.headerEl.offsetHeight;
    this.MAX_HEIGHT = parseInt(this.headerEl.getAttribute('data-header-height-max'))
      || this.DEFAULT_HEIGHT;
    this.REDUCED_HEIGHT = parseInt(this.headerEl.getAttribute('data-header-height-reduced'))
      || this.REDUCED_HEIGHT;

    this.headerTop = {
      el: this.headerEl,
      max: this.MAX_HEIGHT, // this.headerEl.offsetHeight,
      min: this.REDUCED_HEIGHT,
    };

    this.scrollPosition = 0;

    this.mobile = false;

    return true;
  }

  setEvents()
  {
    const mediaMatch = window.matchMedia(`(max-width: ${this.RUNNING_FROM_RES}px)`);
    if (mediaMatch.matches) {
      this.mobile = true;
      this.classes.sticky   += 'Mobile';
      this.classes.stickyUp += 'Mobile';
    }

    window.addEvent('load', () => {
      this.headerSticky();
    });

    window.addEvent('load', () => {
      setTimeout(() => {
        this.headerSticky();

        window.addEvent('scroll.Header', throttle(() => {
          this.headerSticky();
        }, this.THROTTLE_DELAY));

        // problem with throttle here - we don't get sctolltop = 0 usually
        window.addEvent('scrollbarScrolled', (e) => {
          this.scrollPosition = e.detail.offset.y;
          this.headerSticky();
        });

      }, 0);
    });

    this.initMenuOnTopActions();
  }

  initMenuOnTopActions()
  {
    this.idleInterval = setInterval(() => {
      const scrollDelta = this.getScrollPosition() - this.lastBusyScrollTop;

      this.minimizeHeaderIfNotOnTop(scrollDelta);
      this.maximizeHeaderIfOnTop(scrollDelta);

      this.lastBusyScrollTop = this.getScrollPosition();

    }, this.IDLE_DETECTION_DELAY);
  }

  maximizeHeaderIfOnTop(scrollDelta)
  {
    if (this.getScrollPosition() === 0 && scrollDelta === 0
      && this.header.hasClass(this.classes.sticky)) {

      this.header.removeClass(this.classes.sticky);
      this.toggleActiveOnDepenedentElems('remove');
      this.animateHeaderOnTop();
    }
  }

  minimizeHeaderIfNotOnTop(scrollDelta)
  {
    if (this.getScrollPosition() !== 0 && scrollDelta === 0) {

      if (!this.header.hasClass(this.classes.sticky) && this.getScrollPosition() !== 0) {
        this.header.addClass(this.classes.sticky);
        this.toggleActiveOnDepenedentElems('add');
        this.hideHeaderTop();
      }
    }
  }

  headerSticky(scrollY)
  {
    const currentScrollTop = scrollY || this.getScrollPosition();
    const scrollDelta = currentScrollTop - this.lastScrollTop;

    // show top menu on scroll up
    if (scrollDelta > 0) {
      if (scrollDelta > this.MIMUM_DELTA) {
        if (this.debug) console.log('going down (change)');

        this.header.removeClass(this.classes.stickyUp);
        this.header.addClass(this.classes.sticky);
        this.toggleActiveOnDepenedentElems('add');

        this.scrollUp = false;

        this.hideHeaderTop();
      }
    }
    else if (Math.abs(scrollDelta) > this.MIMUM_DELTA) {
      if (this.debug) console.log('going up (change)');

      this.header.addClass(this.classes.stickyUp);

      this.scrollUp = true;

      // this.showHeaderTop();
    }

    this.lastScrollTop = currentScrollTop;
  }

  calculateHeaderHeight()
  {
    const searchFormHeight = this.searchForm
      && this.searchForm.classList.contains('searchForm--show')
      ? this.searchForm.clientHeight
      : 0;
    return this.headerTop.max + searchFormHeight;
  }

  hideHeaderTop()
  {
    if (!this.mobile && !this.noAnimation
      && this.isRunning.hideTop === false
      && this.headerTop.el.style.maxHeight !== `${this.headerTop.min}px`) {

      anime({
        targets: this.headerTop.el,
        // translateY: -1 * parseInt(this.calculateHeaderHeight()),
        maxHeight: `${this.REDUCED_HEIGHT}px`,
        duration: this.TRANSITION_TIME,
        easing: 'easeInOutQuad',
        begin: (/* anim */) => {
          this.isRunning.hideTop = true;
          if (this.debug) console.log('began hideTop');
        },
        complete: (/* anim */) => {
          this.isRunning.hideTop = false;
          if (this.debug) console.log('completed hideTop');
        },
      });
    }
  }

  showHeaderTop()
  {
    // if (!this.mobile && !this.noAnimation
    //   && this.isRunning.showTop === false
    //   && this.headerTop.el.style.maxHeight !== `${this.headerTop.max}px`) {

    //   anime({
    //     targets: this.headerTop.el,
    //     // translateY: this.headerTop.min,
    //     maxHeight: `${this.DEFAULT_HEIGHT}px`,
    //     duration: this.TRANSITION_TIME,
    //     easing: 'easeInOutQuad',
    //     begin: (/* anim */) => {
    //       this.isRunning.showTop = true;
    //       if (this.debug) console.log('began showTop');
    //     },
    //     complete: (/* anim */) => {
    //       this.isRunning.showTop = false;
    //       if (this.debug) console.log('completed showTop');
    //     },
    //   });
    // }
  }

  animateHeaderOnTop()
  {
    if (!this.mobile && !this.noAnimation) {
      anime({
        targets: this.headerTop.el,
        // translateY: this.headerTop.min,
        maxHeight: `${this.DEFAULT_HEIGHT}px`,
        duration: this.TRANSITION_TIME,
        easing: 'easeInOutQuad',
        begin: (/* anim */) => {
          this.isRunning.showTop = true;
          if (this.debug) console.log('began showTop');
        },
        complete: (/* anim */) => {

          this.isRunning.showTop = false;
          if (this.debug) console.log('completed showTop');
        },
      });
    }
  }

  toggleActiveOnDepenedentElems(mode)
  {
    if (!this.activeElArr.length) return;

    if (mode === 'remove') {
      this.activeElArr.forEach((el) => {
        el.removeClass('active');
      });
    }
    else if (mode === 'add') {
      this.activeElArr.forEach((el) => {
        el.addClass('active');
      });
    }
  }

  getScrollPosition() {
    return this.scrollPosition || window.scrollTop();
  }
}
