// export default const globalMixin = {
const globalMixin = {
  // props: {
  //   globalProp: {
  //     type: String,
  //     default: '123',
  //   },
  // },
  mounted() {
    const event = new CustomEvent('vueMounted', {
      detail: {
        componentCount: this.componentCount,
      },
    });
    window.dispatchEvent(event);
  },
};

export { globalMixin };
