export default class ReusableCopy {

  constructor(debug = false)
  {
    this.debug = debug;
    this.debug && console.log('ReusableCopy init');

    if (this.setVars()) {
      this.debug && console.log('ReusableCopy vars init done');
      this.setEvents();
    }
  }

  setVars()
  {
    // this.ReusableCopyArr = [...document.querySelectorAll('.jsReusableCopy')];
    this.ReusableCopyArr = document.querySelectorAll('.jsReusableCopy');
    if (!this.ReusableCopyArr.length) return false;

    return true;
  }

  setEvents()
  {
    this.ReusableCopyArr.forEach((itemEl) => {
      itemEl.addEvent('click', (e) => {
        e.preventDefault();
        this.onClick(e);
      });
    });
  }

  onClick(e)
  {
    window.getSelection().selectAllChildren(e.target);
    document.execCommand('copy');
    console.log('classes copied to clipboard');

  }
}
